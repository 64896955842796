<template>
  <QPage class="flex flex-center">
    <div>
      <QCard flat class="q-pa-md auth-card">
        <QCardSection>
          <div class="flex flex-center">
            <img :src="cdnImg($t({ id: 'images.hc_manager' }), { quality: 100, lossless: true })" loading="lazy" alt="i" width="200">
          </div>
          <div class="flex flex-center">
            <QChip size="sm" v-if="isDev" color="warning" label="dev" />
            <QChip size="sm" v-else color="positive" text-color="white" label="live" />
          </div>
          <div class="q-mt-lg">
            <QForm ref="loginForm" autofocus @submit="login">
              <QInput id="username" v-model="username" placeholder="Username" name="username" outlined />
              <QInput id="password" v-model="password" class="q-mt-sm" placeholder="Password" name="password" outlined type="password" />
              <div class="flex justify-center q-mt-md">
                <HCButton force-label-on-xs icon="uil:sign-in-alt" label="Connexion" type="submit" />
              </div>
            </QForm>
          </div>
        </QCardSection>
      </QCard>
    </div>
  </QPage>
</template>

<script>
export default {
  data () {
    return {
      password: '',
      username: ''
    }
  },
  computed: {
    isDev() { return process.env.HC_ENV === 'dev' },
  },
  methods: {
    async login () {
      try {
        const auth = await this.$store.dispatch('auth/login', {
          username: this.username,
          password: this.password
        })
        if (auth !== null) {
          this.notifySuccess('You are now logged in.')
          this.$router.push({ name: 'dashboard' })
        } else {
          await this.$store.commit('auth/logout')
          this.$router.push({ name: 'home' })
        }
      } catch (e) {
        this.useLogger('Invalid username or password.')
      }
    }
  }
}
</script>
